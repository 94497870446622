<template lang="pug">
  .matchings-table
    .matchings-table-content
      table.container-fluid
        thead
          tr
            th.sync.sortable(@click="handleSorting('car_classes.sync')")
              span {{ $t("car_class_matching.sync") }}
              FaIcon.icon(:icon="sortingIcon('car_classes.sync')")
            th.item-name.sortable(@click="handleSorting('name')")
              span {{ $t("shop_matching.shop_name") }}
              FaIcon.icon(:icon="sortingIcon('name')")
            th.dp.sortable(v-if="currentOrganization.dp" @click="handleSorting('car_classes.dp')")
              span {{ $t("car_class_matching.dp") }}
              FaIcon.icon(:icon="sortingIcon('car_classes.dp')")
            th.inbound.sortable(v-if="currentOrganization.inbound" @click="handleSorting('car_classes.inbound')")
              span {{ $t("car_class_matching.inbound") }}
              FaIcon.icon(:icon="sortingIcon('car_classes.inbound')")
            th.item-name.dropdown
              span {{ $t("shops_sources_car_matching.sources_car") }}
            th.item-name.dropdown
              span {{ $t("shops_car_class_matching.car_class") }}
            th.select-quantity
        tbody(v-if="shopsStore")
          template(v-for="(shop, shopIndex) in shopsAdded")
            ShopItem(
              :key="`new-matching-${shop._addedAt}`"
              :sources-car="shop.sources_cars[0]"
              :shop="shop"
              :selected-shops="selectedShops"
              :shops="shops"
              :shopsUpdated="shopsUpdated"
              :shopsAdded="shopsAdded"
              :shopsDeleted="shopsDeleted"
              :index="shopIndex"
              :sources-car-classes-by-ota-id="sourcesCarClassesByOtaId"
              :selected-sources-car-classes="selectedSourcesCarClasses"
              :add-selected-sources-car-class="addSelectedSourcesCarClass"
              :remove-selected-sources-car-class="removeSelectedSourcesCarClass"
              :sources-cars="sourcesCars"
              :add-selected-sources-car="addSelectedSourcesCar"
              :remove-selected-sources-car="removeSelectedSourcesCar"
              :sources-car-classes-loading="sourcesCarClassesLoading"
              :sources-cars-loading="sourcesCarsLoading"
              :invalid-item-ids="invalidItemIds"
              @select-shop="$emit('select-shop', $event)"
              @update-shop="updateShop"
              @select-car-class="$emit('select-car-class', $event)"
            )
          template(v-for="(shop, shopIndex) in selectedShops")
            ShopItem(
              v-for="(sourcesCar, sourcesCarIndex) in selectedCarsByShop(shop)"
              :key="`${shopIndex}-${sourcesCarIndex}`"
              :sources-car="sourcesCar"
              :shop="shop"
              :selected-shops="selectedShops"
              :shops="shops"
              :shopsAdded="shopsAdded"
              :shopsDeleted="shopsDeleted"
              :shopsUpdated="shopsUpdated"
              :index="sourcesCarIndex"
              :sources-car-classes-by-ota-id="sourcesCarClassesByOtaId"
              :selected-sources-car-classes="selectedSourcesCarClasses"
              :add-selected-sources-car-class="addSelectedSourcesCarClass"
              :remove-selected-sources-car-class="removeSelectedSourcesCarClass"
              :sources-cars="sourcesCars"
              :add-selected-sources-car="addSelectedSourcesCar"
              :remove-selected-sources-car="removeSelectedSourcesCar"
              :sources-car-classes-loading="sourcesCarClassesLoading"
              :sources-cars-loading="sourcesCarsLoading"
              :invalid-item-ids="invalidItemIds"
              @select-shop="$emit('select-shop', $event)"
              @update-shop="updateShop"
              @select-car-class="$emit('select-car-class', $event)"
            )
</template>

<script>
  // mixins
  import withSorting from "@/mixins/withSorting"
  import withBrowserDetect from "@/mixins/withBrowserDetect"
  import { mapGetters } from "vuex"

  export default {
    mixins: [withSorting, withBrowserDetect],

    props: {
      shops: Array,
      selectedShops: Array,
      shopsAdded: Array,
      shopsDeleted: Array,
      shopsUpdated: Array,
      sortingData: Object,
      sourcesCarClassesByOtaId: Object,
      selectedSourcesCarClasses: {
        type: Object,
        required: true
      },
      addSelectedSourcesCarClass: Function,
      removeSelectedSourcesCarClass: Function,
      invalidItemIds: {
        type: Array,
        default: () => new Array()
      },
      sourcesCarClassesLoading: {
        type: Boolean,
        default: () => new Array(),
        required: true
      },
      sourcesCars: {
        type: Array,
        default: () => new Array(),
        required: true
      },
      addSelectedSourcesCar: Function,
      removeSelectedSourcesCar: Function,
      sourcesCarsLoading: {
        type: Boolean,
        default: true
      },
      isShopsWithAlertCars: {
        type: Boolean,
        default: false
      }
    },

    components: {
      ShopItem: () => import("./ShopsSourcesCarItem")
    },

    computed: {
      ...mapGetters(["currentOrganization"]),

      shopsStore() {
        return [...this.shopsAdded, ...this.selectedShops]
      }
    },

    methods: {
      updateShop(localShop, index) {
        this.shopsAdded.splice(index, 1, localShop)
      },

      selectedCarsByShop(shop) {
        const cars = shop.sources_cars.filter(car => car.shop_id == shop.id)

        if (this.isShopsWithAlertCars) {
          return cars.filter(car => car.expired || !car.active)
        }

        return cars
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"

  .matchings-table
    +matchings-table-header

    th
      &.select-quantity
        z-index: 11
        width: 5%
        right: 0
      &.item-name
        &.dropdown
          padding: 0 17px
</style>
